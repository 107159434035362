<template>
  <div class="w-full flex flex-row justify-between items-center"
    :class="{'mt-3 lg:mt-4':index===0,
    'relative':isMobile
    }"
  >
    <div class="group flex flex-row gap-3 items-center transition ease-in-out focus:outline-none"
      :class="[
        getWidth('ThreadCard', 348),
        currentThreadId === thread.id?'opacity-100':'cursor-pointer'
      ]"
      @click="!isCurrentThread(thread.id) ? openChat(thread): null"
    >
      <!-- User image-->
      <div class="flex flex-row items-start w-12">
        <Picture
          :loader-enabled="true"
          is-chat
          :src="thread.targetUser.featuredPictureUrl"
          classes="object-cover w-12 h-12 rounded-full transform duration-200 ease-out border border-black group-hover:border-redAccentLight"
          class="w-12 "
          :class="currentThreadId === thread.id?
            'border-redAccentLight lg:scale-105'
            :'cursor-pointer scale-100 lg:group-hover:scale-105'
          "
        />
        <!-- New message dot -->
        <div class="absolute -right-2 -top-1">
          <template v-if="notReadThreadMessages(thread)>0 && !isBanned">
            <Badge class="mb-3 ml-1" tagName="span" variant="counterSuccess">
              {{ notReadThreadMessages(thread) > 100 ? '100+' : notReadThreadMessages(thread) }}
            </Badge>
          </template>
        </div>
        <ProfilePictureIcons
          :banned-flag = thread.targetUser.isBanned
          :hidden-flag = thread.targetUser.isHidden
        />
      </div>
      <!--- Name + Message excerpt frame -->
      <div class="w-full flex flex-row justify-between">
        <!-- Name + Message excerpt -->
        <div class="w-full flex flex-col justify-between">
          <!-- Name -->
          <div class="flex flex-row justify-between gap-2 items-center">
            <!-- Username -->
            <div class="text-xl lg:text-base group-hover:text-redAccentLight
              flex flex-row lg:mb-1 justify-start items-center w-full"
              :class="currentThreadId === thread.id?'text-redAccentLight':'cursor-pointer group-hover:opacity-70'"
              v-if="thread.targetUser"
            >
              <span
                :class="[
                  {'font-semibold':notReadThreadMessages(thread)>0}
                ]"
                class="truncate"
              >
                {{ thread.targetUser.name }}
              </span>
            </div>
          </div>
          <!-- Message excerpt -->
          <div
            class="w-full text-lg lg:text-sm group-hover:text-redAccentLight truncate"
            :class="[
              currentThreadId === thread.id?'text-redAccentLight opacity-100':'cursor-pointer group-hover:opacity-70',
              notReadThreadMessages(thread)>0?'text-black font-black':'text-grayed'
            ]"
          >
	          <template v-if="userData.isAdmin">
		          {{ thread.adminMessageType }}
	          </template>
	          <template v-else>
		          {{ stripTags(thread.latestMessage) }}
	          </template>
          </div>
        </div>
      </div>
    </div>
    <!-- Thread actions -->
    <div class="z-10">
      <ThreadActions
        v-show="!isMobile || isThreadActionsDropdownVisible"
        :current-thread="thread" isThread :only-delete="!!thread.isAdminMessage"
      />
      <Button
        class="absolute z-20 top-3 right-10"
        v-show="isMobile && isThreadActionsDropdownVisible"
        variant="iconThreadAction"
        @click="toggleThreadActionsDropdown()"
      >
        <base-icon color="fill-grayed" :width="16" :height="16" name="x"/>
      </Button>
      <Button
        class="absolute right-0 top-3"
        v-show="isMobile"
        variant="iconThreadAction"
        @click="toggleThreadActionsDropdown()"
      >
        <base-icon :width="24" :height="24" name="dotsHorizontal"/>
      </Button>
    </div>
  </div>
</template>

<script>
import Dot from "@/utils/dots/Dot";
import {mapGetters} from "vuex";
import ThreadActions from "@/views/content/messages/components/ThreadActions";
import {getWidth, stripTags} from "@/common/helpers/utils";
import themeConfig from "@/themeConfig";
import ProfilePictureIcons from "@/views/content/components/ProfilePictureIcons";
import Badge from "@/utils/crud/components/Badge";
import checkIsMobileDevice from "@/common/helpers/checkIsMobileDevice";
import BaseIcon from "@/utils/icons/BaseIcon";
import router from "@/router";

export default {
  name: "ThreadCard",
  components: {
    BaseIcon,
    Badge,
    Dot,
    ProfilePictureIcons,
    ThreadActions
  },
  props: {
    currentThread: Object,
    thread: Object,
    index: [String, Number],
    threadsDataLength: Number,
    isBanned: Boolean,
    isAdminMessage: true,
  },
  data() {
    return {
      getWidth: getWidth,
      stripTags: stripTags,
      systemAvatar: themeConfig.app.logo,
      isMobile: checkIsMobileDevice(),
      isThreadActionsDropdownVisible: false,
    }
  },
  computed: {
    ...mapGetters('chat', ['notReadCounter']),
    ...mapGetters('account', ['userData']),
    currentThreadId() {
      return this.currentThread ? this.currentThread.id : null;
    },
  },
  methods: {
    notReadThreadMessages(thread) {
      if(this.notReadCounter) {
        return this.notReadCounter.threadsCounter.find(el => el.threadId === thread.id)?.messagesNotRead
      }
      return 0;
    },

    toggleThreadActionsDropdown() {
      this.isThreadActionsDropdownVisible = !this.isThreadActionsDropdownVisible;
    },

    isCurrentThread(threadId) {
      if(this.currentThread && this.$route.params.id) {
        return this.currentThread.id === threadId || this.$route.params.id === threadId;
      }
      return false;
    },

    openChat(thread) {
      if( thread.userFavouritedBy) {
        this.showBlockedThreads = false;
        this.showAdminMessagesThreads = false;
      }
      router.push({
        name: 'chats',
        params:{id: thread.id},
      })
    },
  }
}
</script>