<template>

  <div class="flex flex-col lg:flex-row gap-1"
    :class="{'group-hover:flex': isThread, 'bg-white z-20 absolute top-1 right-8 border border-grayed rounded py-2 px-3':isMobile}"
  >
    <!-- CLOSE THREAD BUTTON -->
    <template v-if="userData.isAdmin">
      <template v-if="currentThread.isThreadClosed">
        <VTooltip>
          <Button
              variant="iconThreadAction"
              @clicked="openThreadAction()"
          >
            <ThreadActionsButton :is-thread="isThread" icon="start"/>
            <span v-if="isMobile">{{ trans('TOOLTIP:OpenThread') }}</span>
          </Button>
          <template #popper>
            {{ trans('TOOLTIP:OpenThread') }}
          </template>
        </VTooltip>
      </template>
      <template v-else>
        <VTooltip>
          <Button
              variant="iconThreadAction"
              @clicked="$root.$emit('open-close-thread-modal', true, currentThread)"
          >
            <ThreadActionsButton :is-thread="isThread" icon="stop"/>
            <span v-if="isMobile">{{ trans('TOOLTIP:CloseThread') }}</span>
          </Button>
          <template #popper>
            {{ trans('TOOLTIP:CloseThread') }}
          </template>
        </VTooltip>
      </template>
    </template>
    <!--  CLOSE THREAD BUTTON  END -->

    <!--   BAN BUTTON   -->
    <!-- Thread not banned -->
    <template v-if="!currentThread.bannedAt && !onlyDelete">
      <VTooltip>
        <Button
          variant="iconThreadAction"
          @clicked="$root.$emit('open-report-modal', currentThread)"
        >
          <ThreadActionsButton :is-thread="isThread" icon="lockFilled"/>
          <span v-if="isMobile">{{ trans('TOOLTIP:Block') }}</span>
        </Button>
        <template #popper>
          {{ trans('TOOLTIP:Block') }}
        </template>
      </VTooltip>
    </template>
    <!-- Thread is banned -->
    <template v-else>
      <VTooltip  v-if="!onlyDelete">
        <Button
            variant="iconThreadAction"
            @clicked="$root.$emit('ban-action-modal', false, currentThread)"
        >
          <ThreadActionsButton :is-thread="isThread" icon="lockOpen"/>
          <span v-if="isMobile">{{ trans('TOOLTIP:Unblock') }}</span>
        </Button>
        <template #popper>
          {{ trans('TOOLTIP:Unblock') }}
        </template>
      </VTooltip>
    </template>
    <!-- BAN BUTTON END -->

    <!-- DELETE BUTTON -->
	  <template>
		  <VTooltip>
			  <Button
					  variant="iconThreadAction"
					  @clicked="$root.$emit('open-delete-modal', true, currentThread)"
			  >
          <ThreadActionsButton :is-thread="isThread" icon="trash"/>
          <span v-if="isMobile">{{ trans('TOOLTIP:DeleteChat') }}</span>
			  </Button>
			  <template #popper>
				  {{ trans('TOOLTIP:DeleteChat') }}
			  </template>
		  </VTooltip>
	  </template>
    <!-- DELETE BUTTON END -->

    <!-- ADD TO FAVOURITES BUTTON -->
    <template v-if="currentThread.bannedAt === null && !onlyDelete">
      <template v-if="!currentThread.userFavouritedBy">
        <VTooltip>
          <Button
              variant="iconThreadAction"
              @clicked="addToFavUsers()"
          >
            <ThreadActionsButton :is-thread="isThread" icon="heartFilled"/>
            <span v-if="isMobile">{{ trans('TOOLTIP:AddToFavourites') }}</span>
          </Button>
          <template #popper>
            {{ trans('TOOLTIP:AddToFavourites') }}
          </template>
        </VTooltip>
      </template>
      <template v-else>
        <VTooltip>
          <Button
              variant="iconThreadAction"
              @clicked="removeFromFavUsers()"
          >
            <ThreadActionsButton :is-thread="isThread" icon="heart"/>
            <span v-if="isMobile">{{ trans('TOOLTIP:RemoveFromFavourites') }}</span>
          </Button>
          <template #popper>
            {{ trans('TOOLTIP:RemoveFromFavourites') }}
          </template>
        </VTooltip>
      </template>
    </template>

  </div>
</template>

<script>
import Button from "@/utils/crud/components/Button";
import {mapActions, mapGetters} from "vuex";
import Modal from "@/utils/modal/Modal";
import ContactForm from "@/views/content/components/ContactForm";
import ThreadActionsButton from "@/views/content/messages/components/ThreadActionsButton";
import checkIsMobileDevice from "@/common/helpers/checkIsMobileDevice";

export default {
  name: "ThreadActions",
  components: {ContactForm, Modal, Button, ThreadActionsButton},
  props: {
    isThread: Boolean,
    visibleOnHover: Boolean,
    currentThread: Object,
	  onlyDelete: {
			default: false,
		  type: Boolean|Number,
	  }
  },
  data() {
    return {
      iconLockFilled: false,
      iconUnlockFilled: false,
      iconHeartFilled: false,
      isMobile: checkIsMobileDevice(),
    }
  },
  watch: {
    'currentThread.bannedAt': {

      immediate: true,
      deep: true,
      handler(val) {
        this.iconUnlockFilled = val !== null;
      }
    },
  },
  computed: {
    ...mapGetters('chat', [ 'banLoading', 'banned', 'threadDeleted', 'threadDeleting']),
    ...mapGetters('contact', ['adminMessageSent']),
    ...mapGetters('account', ['userData']),

	  getIconSize() {
			return this.isThread ? 12 : 16;
	  }
  },
  methods: {
    ...mapActions('favourites', ['removeFavouriteProfile', 'addFavouriteProfile']),
    ...mapActions('chat', ['banThreadRequest', 'deleteThreadRequest', 'openThreadByAdminRequest']),

    addToFavUsers() {
      this.addFavouriteProfile({favouriteUsername: this.currentThread.targetUser.username});
    },

    removeFromFavUsers() {
      this.removeFavouriteProfile({favouriteUsername: this.currentThread.targetUser.username})
      this.$root.$emit('unfav-action')
    },

	  openThreadAction() {
			this.openThreadByAdminRequest({threadId: this.currentThread.id})
	  }
  },
  mounted() {
    this.iconLockFilled = this.currentThread.bannedAt !== null
    this.iconHeartFilled = this.currentThread.userFavouritedBy
  }
}
</script>

<style>

</style>
