<template>
  <label
      for="toggle"
      class="flex items-center cursor-pointer"
  >
    <!-- toggle -->
    <div class="relative">
      <!-- input -->
      <input :value="isChecked" @change="onChange()" id="toggle" type="checkbox" class="sr-only" />
      <!-- line -->
      <div class="w-10 h-4 bg-gray-400 rounded-full shadow-inner"></div>
      <!-- dot -->
      <div class="dot-switch absolute w-6 h-6 bg-white rounded-full shadow -left-1 -top-1 transition"></div>
    </div>
    <!-- label -->
    <div class="ml-3 text-gray-700 font-medium">
      {{ label }}
    </div>
  </label>
</template>
<script>

export default {
  name: 'SwitchCheckbox',
  props: {
    value: [String, Object, Number, Boolean, Array],
    label: [String],
    textBase: Boolean,
  },
  data () {
    return {
      isChecked: false,
    }
  },
  methods: {
    onChange () {
      this.isChecked = this.isChecked ? 1 : 0
      this.$emit('input', this.isChecked)
    },
  },
  watch: {
    value: {
      immediate: true,
      handler (val) {
        this.isChecked = val
      },
    },
  },
}
</script>

<style>

/* Toggle A */
input:checked ~ .dot-switch {
  transform: translateX(100%);
  background-color: black;
}

</style>