<template>
  <div class="grid grid-cols-4 gap-2">
    <div v-for="(color, index) in chatColors" :key="index">
      <div class="w-6 h-6 rounded-full transform flex justify-center items-center"
        :class="[color.color,
          {'lg:scale-125 drop-shadow-md cursor-default': areObjectsEqual(color.name, currentColor)},
          {'lg:hover:scale-125 cursor-pointer':!areObjectsEqual(color.name, currentColor)}
        ]"
        @click="chooseColorAction(color)"
      >
        <span :class="[color.text]">
          T
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import {areObjectsEqual} from "@/common/helpers/utils";
import {chatColors} from "@/config/chatColors";

export default {
  name: "ChatColorsPalette",
  props: {
    currentColor: String,
  },
  data() {
    return {
      areObjectsEqual: areObjectsEqual,
	    chatColors: chatColors
    }
  },
  computed: {
    ...mapGetters('lang', ['configData']),
    ...mapGetters('chat', ['currentThread']),
  },
  methods: {
    ...mapActions('chat', ['chooseColorRequest']),

    chooseColorAction(color){
      if(!areObjectsEqual(color, this.currentColor)) {
        this.chooseColorRequest({color: color.name})
      }
    },
  }
}
</script>
