<template>
  <div class="w-full flex flex-row justify-between items-center"
       :class="{'mt-3 lg:mt-4':index===0}"
  >
    <div class="group flex flex-row gap-3 items-center transition duration-200 ease-in-out
      cursor-pointer focus:outline-none opacity-70 hover:opacity-100"
      :class="[
        getWidth('ThreadCard', 348),
        currentThreadId === thread.id?'opacity-100':'cursor-pointer',
       ]"
    >
	    <div class="relative flex flex-row items-start w-12">
        <Picture
          is-chat
          :src="systemAvatar"
          classes="object-cover w-12 h-12 rounded-full
            transform duration-200 ease-out border border-black
            group-hover:border-redAccentLight"
          class="w-12"
          :class="currentThreadId === thread.id?
            'border-redAccentLight lg:scale-105':
            'cursor-pointer scale-100 lg:group-hover:scale-105'
          "
        />
        <!-- New message dot -->
        <div class="absolute -right-2 -top-1">
          <template v-if="notReadThreadMessages(thread)>0 && !isBanned">
            <Badge class="mb-3 ml-1" tagName="span" variant="counterSuccess">
              {{ notReadThreadMessages(thread) > 100 ? '100+' : notReadThreadMessages(thread) }}
            </Badge>
          </template>
        </div>
        <ProfilePictureIcons
            :banned-flag = thread.targetUser.isBanned
            :hidden-flag = thread.targetUser.isHidden
        />
	    </div>
	    <!--- All other things to the right -->
      <div class="w-full flex flex-row justify-between">
        <div class="w-full flex flex-col justify-between">
          <div class="flex flex-row justify-between gap-2 items-center">
				    <!-- Username -->
            <div class="text-xl lg:text-base group-hover:text-redAccentLight
              flex flex-row lg:mb-1 justify-start items-center w-full"
                 :class="currentThreadId === thread.id?'text-redAccentLight':'cursor-pointer group-hover:opacity-70'"
                 v-if="thread.targetUser"
            >
              <span
		              :class="[
                  getWidth('ThreadCardName', 348),
                  {'font-semibold':notReadThreadMessages(thread)>0}
                ]"
		              class="truncate"
              >
                {{ thread.adminMessageType }}
              </span>
				    </div>
			    </div>
			    <!-- Message excerpt -->
			    <div
              class="w-full text-lg lg:text-sm group-hover:text-redAccentLight truncate"
					    :class="[
              currentThreadId === thread.id?'text-redAccentLight opacity-100':'cursor-pointer group-hover:opacity-70',
              notReadThreadMessages(thread)>0?'text-black font-black':'text-grayed'
          ]"
			    >
				    <template v-if="userData.isAdmin">
					    {{ thread.adminMessageType }}
				    </template>
				    <template v-else>
					    {{ stripTags(thread.latestMessage) }}
				    </template>
			    </div>
		    </div>
	    </div>
      <!-- Thread actions -->
      <base-icon v-if="isMobile" :width="24" :height="24" name="dotsHorizontal"/>
      <ThreadActions v-else :current-thread="thread" isThread :only-delete="!!thread.isAdminMessage" />
    </div>
    <hr class=" my-2 border-solid border-grayedHR" v-if="index !== threadsDataLength - 1" />
  </div>
</template>

<script>
import Dot from "@/utils/dots/Dot";
import {mapGetters} from "vuex";
import ThreadActions from "@/views/content/messages/components/ThreadActions";
import {getWidth, stripTags} from "@/common/helpers/utils";
import themeConfig from "@/themeConfig";
import ProfilePictureIcons from "@/views/content/components/ProfilePictureIcons.vue";
import Badge from "@/utils/crud/components/Badge.vue";

export default {
  name: "ThreadCardAdminMessages",
  components: {Badge, ProfilePictureIcons, ThreadActions, Dot},
  props: {
    currentThread: Object,
    thread: Object,
    index: [String, Number],
    threadsDataLength: Number,
    isBanned: Boolean,
    isAdminMessage: true,
  },
  data() {
    return {
      stripTags: stripTags,
      getWidth: getWidth,
      systemAvatar: themeConfig.app.logo,
    }
  },
  computed: {
    ...mapGetters('chat', ['notReadCounter']),
    ...mapGetters('account', ['userData']),
    currentThreadId() {
      return this.currentThread ? this.currentThread.id : null;
    },
  },
  methods: {
    notReadThreadMessages(thread) {
      if(this.notReadCounter) {
        return this.notReadCounter.threadsCounter.find(el => el.threadId === thread.id)?.messagesNotRead
      }
      return 0;
    },
  }
}
</script>

<style>

.img-gray {
  filter: grayscale(1);
}

</style>