var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"w-full flex flex-row justify-between items-center",class:{'mt-3 lg:mt-4':_vm.index===0,
    'relative':_vm.isMobile
    }},[_c('div',{staticClass:"group flex flex-row gap-3 items-center transition ease-in-out focus:outline-none",class:[
        _vm.getWidth('ThreadCard', 348),
        _vm.currentThreadId === _vm.thread.id?'opacity-100':'cursor-pointer'
      ],on:{"click":function($event){!_vm.isCurrentThread(_vm.thread.id) ? _vm.openChat(_vm.thread): null}}},[_c('div',{staticClass:"flex flex-row items-start w-12"},[_c('Picture',{staticClass:"w-12 ",class:_vm.currentThreadId === _vm.thread.id?
            'border-redAccentLight lg:scale-105'
            :'cursor-pointer scale-100 lg:group-hover:scale-105',attrs:{"loader-enabled":true,"is-chat":"","src":_vm.thread.targetUser.featuredPictureUrl,"classes":"object-cover w-12 h-12 rounded-full transform duration-200 ease-out border border-black group-hover:border-redAccentLight"}}),_c('div',{staticClass:"absolute -right-2 -top-1"},[(_vm.notReadThreadMessages(_vm.thread)>0 && !_vm.isBanned)?[_c('Badge',{staticClass:"mb-3 ml-1",attrs:{"tagName":"span","variant":"counterSuccess"}},[_vm._v(" "+_vm._s(_vm.notReadThreadMessages(_vm.thread) > 100 ? '100+' : _vm.notReadThreadMessages(_vm.thread))+" ")])]:_vm._e()],2),_c('ProfilePictureIcons',{attrs:{"banned-flag":_vm.thread.targetUser.isBanned,"hidden-flag":_vm.thread.targetUser.isHidden}})],1),_c('div',{staticClass:"w-full flex flex-row justify-between"},[_c('div',{staticClass:"w-full flex flex-col justify-between"},[_c('div',{staticClass:"flex flex-row justify-between gap-2 items-center"},[(_vm.thread.targetUser)?_c('div',{staticClass:"text-xl lg:text-base group-hover:text-redAccentLight\n              flex flex-row lg:mb-1 justify-start items-center w-full",class:_vm.currentThreadId === _vm.thread.id?'text-redAccentLight':'cursor-pointer group-hover:opacity-70'},[_c('span',{staticClass:"truncate",class:[
                  {'font-semibold':_vm.notReadThreadMessages(_vm.thread)>0}
                ]},[_vm._v(" "+_vm._s(_vm.thread.targetUser.name)+" ")])]):_vm._e()]),_c('div',{staticClass:"w-full text-lg lg:text-sm group-hover:text-redAccentLight truncate",class:[
              _vm.currentThreadId === _vm.thread.id?'text-redAccentLight opacity-100':'cursor-pointer group-hover:opacity-70',
              _vm.notReadThreadMessages(_vm.thread)>0?'text-black font-black':'text-grayed'
            ]},[(_vm.userData.isAdmin)?[_vm._v(" "+_vm._s(_vm.thread.adminMessageType)+" ")]:[_vm._v(" "+_vm._s(_vm.stripTags(_vm.thread.latestMessage))+" ")]],2)])])]),_c('div',{staticClass:"z-10"},[_c('ThreadActions',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isMobile || _vm.isThreadActionsDropdownVisible),expression:"!isMobile || isThreadActionsDropdownVisible"}],attrs:{"current-thread":_vm.thread,"isThread":"","only-delete":!!_vm.thread.isAdminMessage}}),_c('Button',{directives:[{name:"show",rawName:"v-show",value:(_vm.isMobile && _vm.isThreadActionsDropdownVisible),expression:"isMobile && isThreadActionsDropdownVisible"}],staticClass:"absolute z-20 top-3 right-10",attrs:{"variant":"iconThreadAction"},on:{"click":function($event){return _vm.toggleThreadActionsDropdown()}}},[_c('base-icon',{attrs:{"color":"fill-grayed","width":16,"height":16,"name":"x"}})],1),_c('Button',{directives:[{name:"show",rawName:"v-show",value:(_vm.isMobile),expression:"isMobile"}],staticClass:"absolute right-0 top-3",attrs:{"variant":"iconThreadAction"},on:{"click":function($event){return _vm.toggleThreadActionsDropdown()}}},[_c('base-icon',{attrs:{"width":24,"height":24,"name":"dotsHorizontal"}})],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }