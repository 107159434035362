<template>
  <div class="w-full flex flex-col gap-3 lg:flex-row lg:gap-6 lg:justify-between items-start">
    <slot/>
  </div>
</template>

<script>
export default {
  name: "PageContentWithSidebar"
}
</script>
