var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"flex flex-col items-center justify-between w-full m-0 mt-auto bg-white relative rounded-lg",on:{"click":function($event){(_vm.currentThread.targetUser.isBanned || _vm.currentThread.bannedAt || _vm.userData.isBanned) ? _vm.openSendMessageModalFail(true) : null}}},[_c('div',{staticClass:"w-full"},[_c('VueTrix',{ref:"messageInput",staticClass:"block text-xl lg:text-base w-full lg:w-full outline-none focus:text-grayed border-none messages-chat px-3 lg:px-6 pt-4 pb-0 ",attrs:{"type":"text","id":"messageInput","placeholder":_vm.editorDisabledProp ? '' : _vm.currentThread.bannedAt
            ? _vm.trans('PLACEHOLDER:ChatFormUnlockToChat')
            : _vm.trans('PLACEHOLDER:ChatForm'),"inputName":"msgContent","name":"message","required":"","autocomplete":"off","disabledEditor":!!_vm.disableEditor || !!_vm.currentThread.targetUser.isBanned},on:{"trix-focus":_vm.emitFocusTrixEmit,"trix-blur":_vm.emitBlurTrixEmit},model:{value:(_vm.messageContent),callback:function ($$v) {_vm.messageContent=$$v},expression:"messageContent"}})],1),_c('div',{staticClass:"flex w-full justify-between px-3 lg:px-6 bg-white pt-2 pb-3 lg:pb-4 items-center rounded-lg"},[(_vm.currentThread.targetUser.isBanned || _vm.currentThread.bannedAt !== null || _vm.userData.isBanned)?_c('div',{staticClass:"absolute w-full h-24 z-10"}):_vm._e(),_c('div',{staticClass:"flex flex-row gap-6 items-center"},[(!_vm.currentThread.isAdminMessage)?_c('VDropdown',{attrs:{"placement":"top"},scopedSlots:_vm._u([{key:"popper",fn:function(){return [_c('ChatColorsPalette',{attrs:{"current-color":_vm.currentThread.color}})]},proxy:true}],null,false,3802598188)},[_c('t-button',{attrs:{"variant":"icon"}},[_c('VTooltip',{scopedSlots:_vm._u([{key:"popper",fn:function(){return [_vm._v(" "+_vm._s(_vm.trans('TOOLTIP:ChatColor'))+" ")]},proxy:true}],null,false,2365241103)},[_c('base-icon',{attrs:{"height":_vm.isMobile?35:25,"width":_vm.isMobile?35:25,"name":"palette"}})],1)],1)],1):_vm._e(),_vm._t("onEnterSend")],2),_c('div',{staticClass:"flex flex-row gap-6 items-center"},[(!_vm.isMobile)?_c('emoji-picker',{attrs:{"search":_vm.search},on:{"emoji":_vm.insert},scopedSlots:_vm._u([{key:"emoji-invoker",fn:function(ref){
            var clickEvent = ref.events.click;
return _c('div',{on:{"click":function($event){$event.stopPropagation();return clickEvent.apply(null, arguments)}}},[_c('VTooltip',{scopedSlots:_vm._u([{key:"popper",fn:function(){return [_vm._v(" "+_vm._s(_vm.trans('TOOLTIP:Emojis'))+" ")]},proxy:true}],null,true)},[_c('base-icon',{class:{
									  'cursor-pointer hover:text-redAccentLight hover:opacity-70':
									    !!!_vm.currentThread.bannedAt && !_vm.editorDisabledProp && !_vm.disableEditor,
									  'opacity-70': _vm.currentThread.bannedAt || _vm.editorDisabledProp
									},attrs:{"height":_vm.isMobile?35:25,"width":_vm.isMobile?35:25,"name":"smile"}})],1)],1)}},{key:"emoji-picker",fn:function(ref){
									var emojis = ref.emojis;
									var insert = ref.insert;
									var display = ref.display;
return (!!!_vm.currentThread.bannedAt && !_vm.editorDisabledProp && !_vm.disableEditor)?_c('div',{staticClass:"emoji-picker"},[_c('div',_vm._l((emojis),function(emojiGroup,postCategory){return _c('div',{key:postCategory},[(postCategory !== 'Frequently used')?_c('div',{staticClass:"grid grid-cols-10 gap-2 items-center justify-between z-50"},_vm._l((emojiGroup),function(emoji,emojiName){return _c('span',{key:emojiName,staticClass:"w-6 h-6 text-2xl text-center",attrs:{"title":emojiName},on:{"click":function($event){_vm.currentThread.bannedAt ? null : insert(emoji)}}},[_vm._v(" "+_vm._s(emoji)+" ")])}),0):_vm._e()])}),0)]):_vm._e()}}],null,true)}):_vm._e(),_c('Button',{attrs:{"variant":_vm.isButtonDisabled() ? 'iconDisabled' : 'icon',"disabled":_vm.isButtonDisabled() || _vm.emptyMessage},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }this.onEnterSend ? _vm.sendMessageAction : ''},"clicked":_vm.sendMessageAction}},[_c('VTooltip',{scopedSlots:_vm._u([{key:"popper",fn:function(){return [(_vm.userData.isBanned)?[_vm._v(" "+_vm._s(_vm.trans('MESSAGE:ChatIsBlockedBecauseOfBan1') + ' ' + _vm.trans('MESSAGE:ChatIsBlockedBecauseOfBan2'))+" ")]:[_vm._v(" "+_vm._s(_vm.trans('TOOLTIP:SendMessage'))+" ")]]},proxy:true}])},[_c('base-icon',{attrs:{"height":_vm.isMobile?35:25,"width":_vm.isMobile?35:25,"name":"sendMessage"}})],1)],1)],1)])]),(_vm.showSendMessageFailModal)?_c('ModalFailedAction',{attrs:{"show-modal":_vm.showSendMessageFailModal},on:{"modal-clicked":function($event){return _vm.openSendMessageModalFail(false)}}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }