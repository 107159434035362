<template>
  <base-icon
    :width="isMobile?20:14"
    :height="isMobile?20:14"
    :name="icon"
  />
</template>

<script>

import BaseIcon from "@/utils/icons/BaseIcon";
import checkIsMobileDevice from "@/common/helpers/checkIsMobileDevice";

let isMobile;
export default {
  name: "ThreadActionsButton",
  components: { BaseIcon},
  props: {
    isThread: Boolean,
    icon: String,
  },
  setup() {
    const isMobile = checkIsMobileDevice();
    return {
      isMobile
    }
  }

}
</script>
