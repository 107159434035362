<template>
  <div class="flex flex-col w-full lg:w-sidebar">
    <slot/>
  </div>
</template>

<script>
export default {
  name: "Sidebar"
}
</script>
